var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h4", { staticClass: "card-title  pb-2" }, [_vm._v(" Details ")]),
          _c("ul", { attrs: { id: "StickyUF" } }, [
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-addUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.disabledAdd },
                  on: { click: _vm.addBtn }
                },
                [_c("i", { staticClass: "fas fa-plus text-white" })]
              )
            ]),
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-duplicateUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.disabledAdd },
                  on: { click: _vm.duplicateBtn }
                },
                [_c("i", { staticClass: "far fa-copy text-white" })]
              )
            ]),
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-editUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.disabledUpdate },
                  on: { click: _vm.editBtn }
                },
                [_c("i", { staticClass: "fas fa-pencil-alt text-white" })]
              )
            ]),
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-deleteUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.canDelte },
                  on: { click: _vm.deleteUser }
                },
                [_c("i", { staticClass: "far fa-trash-alt text-white" })]
              )
            ]),
            _vm._m(0),
            _vm._m(1),
            _vm._m(2)
          ]),
          _c("fieldset", { attrs: { disabled: _vm.disabled } }, [
            _c(
              "form",
              {
                staticClass: "needs-validation",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.formSubmit($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Type d'utilisateur *")
                        ]),
                        _c("multiselect", {
                          class: { disabledMS: _vm.disabled },
                          attrs: {
                            searchable: true,
                            "track-by": "id",
                            label: "name",
                            "select-label": "",
                            options: _vm.types,
                            "allow-empty": false,
                            "deselect-label": ""
                          },
                          on: {
                            close: function($event) {
                              return _vm.getEmployees()
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleType",
                              fn: function(ref) {
                                var type = ref.type
                                return [_vm._v(_vm._s(type.name))]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.userForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "type", $$v)
                            },
                            expression: "userForm.type"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.email.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.email.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm.userForm.type.id == "supplier_staff"
                    ? _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-email-input" } },
                                [_vm._v("Prestataire *")]
                              ),
                              _c("multiselect", {
                                class: { disabledMS: _vm.disabled },
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.suppliers,
                                  "select-label": "",
                                  "deselect-label": "",
                                  placeholder: "Select Supplier",
                                  "allow-empty": false
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.getEmployeesPrestataire()
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleSupplier",
                                      fn: function(ref) {
                                        var supplier = ref.supplier
                                        return [_vm._v(_vm._s(supplier.name))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4237759933
                                ),
                                model: {
                                  value: _vm.userForm.supplier,
                                  callback: function($$v) {
                                    _vm.$set(_vm.userForm, "supplier", $$v)
                                  },
                                  expression: "userForm.supplier"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.userForm.type.id != "other"
                    ? _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-3 " },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-email-input" } },
                                [_vm._v("Employees *")]
                              ),
                              _c("multiselect", {
                                class: { disabledMS: _vm.disabled },
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "fullName",
                                  "select-label": "",
                                  "deselect-label": "",
                                  multiple: false,
                                  options: _vm.employeesList,
                                  "allow-empty": false
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.setEmployees()
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleLabel",
                                      fn: function(ref) {
                                        var option = ref.option
                                        return [
                                          _vm._v(
                                            " " + _vm._s(option.fullName) + " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  438401532
                                ),
                                model: {
                                  value: _vm.userForm.employees,
                                  callback: function($$v) {
                                    _vm.$set(_vm.userForm, "employees", $$v)
                                  },
                                  expression: "userForm.employees"
                                }
                              }),
                              _vm.submitted && _vm.$v.userForm.employees.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      !_vm.$v.userForm.employees.required
                                        ? _c("span", [
                                            _vm._v(
                                              "La référence du contrat est obligatoire."
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "col-xs-6 col-md-6 col-lg-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Superviseur *")
                        ]),
                        _c("multiselect", {
                          class: { disabledMS: _vm.disabled },
                          attrs: {
                            searchable: true,
                            "track-by": "id",
                            label: "fullName",
                            "select-label": "",
                            "deselect-label": "",
                            multiple: false,
                            options: _vm.employeesList,
                            "allow-empty": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleLabel",
                              fn: function(ref) {
                                var option = ref.option
                                return [
                                  _vm._v(" " + _vm._s(option.fullName) + " ")
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.userForm.superior_id,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "superior_id", $$v)
                            },
                            expression: "userForm.superior_id"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.superior_id.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.superior_id.required
                                ? _c("span", [
                                    _vm._v(" Le champ est obligatoire.")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Nom *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.firstName,
                            expression: "userForm.firstName"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: "",
                          disabled: true
                        },
                        domProps: { value: _vm.userForm.firstName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "firstName",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Prénom *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.lastName,
                            expression: "userForm.lastName"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "isDisabled disabledBG":
                            _vm.userForm.type.id != "other"
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: "",
                          disabled: true
                        },
                        domProps: { value: _vm.userForm.lastName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "lastName",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("N° Employé *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.id_number,
                            expression: "userForm.id_number"
                          }
                        ],
                        staticClass: "form-control ",
                        class: {
                          "isDisabled disabledBG":
                            _vm.userForm.type.id != "other"
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          disabled: true
                        },
                        domProps: { value: _vm.userForm.id_number },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "id_number",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Email *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.email,
                            expression: "userForm.email"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.email.$error,
                          disabledMS: _vm.disabled
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.userForm, "email", $event.target.value)
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.email.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.email.$error
                              ? _c("span", [
                                  _vm._v(
                                    "le champ est obligatoire et doit être unique."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Nom d'utilisateur *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.username,
                            expression: "userForm.username"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.username.$error,
                          disabledMS: _vm.disabled
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.username },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "username",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.username.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.username.$error
                              ? _c("span", [
                                  _vm._v(
                                    "le champ est obligatoire et doit être unique."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Mot de passe *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.password,
                            expression: "userForm.password"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.password.$error,
                          disabledMS: _vm.disabled
                        },
                        attrs: {
                          id: "projectname",
                          type: "password",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.password.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.password.required
                              ? _c("span", [
                                  _vm._v(
                                    "La référence du contrat est obligatoire."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Rôle *")
                        ]),
                        _c("multiselect", {
                          class: { disabledMS: _vm.disabled },
                          attrs: {
                            searchable: true,
                            "track-by": "id",
                            label: "display_name",
                            options: _vm.roles,
                            placeholder: "Select user Role",
                            "allow-empty": false,
                            "select-label": "",
                            "deselect-label": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleRole",
                              fn: function(ref) {
                                var role = ref.role
                                return [_vm._v(_vm._s(role.display_name))]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.userForm.role,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "role", $$v)
                            },
                            expression: "userForm.role"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.role.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.role.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md col-lg" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Base de vie *")
                        ]),
                        _c("Multiselect", {
                          class: { disabledMS: _vm.disabled },
                          attrs: {
                            options: _vm.bases,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "preserve-search": true,
                            "select-label": "",
                            "deselect-label": "",
                            placeholder: "Selectionner une ou plusieurs bases",
                            label: "name",
                            "track-by": "id",
                            "preselect-first": true
                          },
                          model: {
                            value: _vm.userForm.selectedBases,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "selectedBases", $$v)
                            },
                            expression: "userForm.selectedBases"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.selectedBases.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.selectedBases.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabled,
                        expression: "!disabled"
                      }
                    ],
                    staticClass: "col-md-12"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light btn-label float-right ml-2",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelBtn }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-times-circle text-danger label-icon"
                        }),
                        _vm._v(" Annuler ")
                      ]
                    ),
                    _vm._m(3)
                  ]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "button",
        {
          staticClass:
            "d-none btn btn-uploadUF rounded-pillUF float-right mr-1 mt-1",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "fas fa-file-upload  text-white" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "button",
        {
          staticClass:
            "d-none btn btn-downloadUF rounded-pillUF float-right mr-1 mt-1",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "fas fa-file-download text-white" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "button",
        {
          staticClass:
            "d-none btn btn-printUF rounded-pillUF float-right mr-1 mt-1",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "fas fa-print" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-label btn-primary float-right",
        attrs: { type: "submit" }
      },
      [
        _c("i", { staticClass: "far fa-save label-icon " }),
        _vm._v(" Enregistrer ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }